import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';



@Injectable({ providedIn: 'root' })
export class ClienteService {
    constructor(private http: HttpClient) { }

    getAll() {
        return this.http.get<any>(`${environment.apiUrl}clientes`);
    }

    getCliente(cliente_id) {
        return this.http.get<any>(`${environment.apiUrl}clientes/` + cliente_id);
    }

    delete(cliente_id) {
        return this.http.delete<any>(`${environment.apiUrl}clientes/` + cliente_id);
    }

    add(data) {

        const postData = {
            no_cliente: data.no_cliente.value,
            nombre: data.nombre.value,
            sucursal: data.sucursal.value,
            contacto: data.contacto.value,
            contacto_numero: data.contacto_numero.value,
            email: data.email.value,
            observaciones: data.observaciones.value,
            rfc: data.rfc.value,
            pais_id: data.pais_id.value,
            estado_id: data.estado_id.value,
            giro_id: data.giro_id.value,
            direccion: data.direccion.value
        };

        return this.http.post<any>(`${environment.apiUrl}clientes`, postData);
    }

    update(data, id) {

      const postData = {
        no_cliente: data.no_cliente.value,
        nombre: data.nombre.value,
        sucursal: data.sucursal.value,
        contacto: data.contacto.value,
        contacto_numero: data.contacto_numero.value,
        email: data.email.value,
        observaciones: data.observaciones.value,
        rfc: data.rfc.value,
        pais_id: data.pais_id.value,
        estado_id: data.estado_id.value,
        giro_id: data.giro_id.value,
        direccion: data.direccion.value
      };

      return this.http.put<any>(`${environment.apiUrl}clientes/` + id, postData);
    }

    getGiros() {
        return this.http.get<any>(`${environment.apiUrl}giros`);
    }
}
