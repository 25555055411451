import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';



@Injectable({ providedIn: 'root' })
export class EstudioService {
    constructor(private http: HttpClient) { }

    getAll() {
        return this.http.get<any>(`${environment.apiUrl}estudios`);
    }

    getTipos() {
        return this.http.get<any>(`${environment.apiUrl}estudiotipos`);
    }

    get(id) {
        return this.http.get<any>(`${environment.apiUrl}estudios/` + id);
    }

    delete(id) {
        return this.http.delete<any>(`${environment.apiUrl}estudios/` + id);
    }

    add(cliente_id, estudio) {

        const postData = {
            cliente_id: cliente_id,
            tipo_id: estudio.tipo_id,
            periodicidad: estudio.periodicidad,
            mes_inicio: estudio.mes_inicio,
        };

        return this.http.post<any>(`${environment.apiUrl}estudios`, postData);
    }

    addReporte(data, estudio_id) {

      const postData = {
        estudio_id: estudio_id,
        usuario_id: data.usuario_id.value,
        nombre: data.year.value + ' ' + data.mes.value,
        fecha: data.fecha.value,
      };

      return this.http.post<any>(`${environment.apiUrl}reportes`, postData);
    }

  editReporte(data, reporte_id) {

    const putData = {
      usuario_id: data.usuario_id.value,
      nombre: data.year.value + ' ' + data.mes.value,
      fecha: data.fecha.value,
    };

    return this.http.put<any>(`${environment.apiUrl}reportes/` + reporte_id, putData);
  }

  deleteReporte(id) {
    return this.http.delete<any>(`${environment.apiUrl}reportes/` + id);
  }
}
