import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {AuthenticationService} from '../_services';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {

    constructor(private router: Router,private authenticationService: AuthenticationService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (localStorage.getItem('access_token')) {
            // logged in so return true
            // revisando si está guardada la información del usuario
            if (localStorage.getItem('logged_user')) {
            } else {
                this.authenticationService.me()
                    .subscribe(
                        data => {
                            localStorage.setItem('logged_user', JSON.stringify(data.data));
                            return true;
                        },
                        error => {
                            alert('Error al obtener información del usuario');
                        });
            }
            return true;
        }

        // not logged in so redirect to login page with the return url
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
        return false;
    }
}
