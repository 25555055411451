import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';



@Injectable({ providedIn: 'root' })
export class GeoService {
    constructor(private http: HttpClient) { }

    getPaises() {
        return this.http.get<any>(`${environment.apiUrl}paises`);
    }

    getPais(pais_id) {
        return this.http.get<any>(`${environment.apiUrl}paises/` + pais_id);
    }
}
