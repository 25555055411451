export class User {
  id: number;
  name: string;
  password: string;
  email: string;
  password_confirmation: string;
  cliente: any;
  roles: any;
  extintores: any;
  ordenes: any;

  constructor() {
    this.roles = [{}];
    this.extintores = [{}];
    this.ordenes = [{}];
  }
}
