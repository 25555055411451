import {User} from './user';

export class Cliente {
    id: number;
    no_cliente: string;
    nombre: string;
    sucursal: string;
    contacto: string;
    contacto_numero: string;
    observaciones: string;
    user_id: number;
    user: User;
    rfc: string;
    direccion: string;
    estado_id: number;
    estado: object;
    pais_id: number;
    pais: object;
    giro_id: number;
    giro: object;
    proyectos: object[];
    estudios: object[];

    constructor() {
        this.user =  new User();
        this.estado = {};
        this.pais = {};
        this.giro = {};
        this.proyectos = [];
    }
}

