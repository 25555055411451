import {Cliente} from './cliente';

export class Estudio {
    id: number;
    folio: string;
    periodicidad: number;
    periodicidad_letra: string;
    mes_inicio: number;
    tipo_id: number;
    tipo: any;
    cliente_id: number;
    cliente: Cliente;
    ubicaciones: any;
    reportes: any;
    carpetas: any;

    constructor() {
        this.tipo = {nombre: ''};
        this.cliente = new Cliente();
        this.ubicaciones = [];
        this.reportes = [];
        this.carpetas = [];
        this.tipo_id = 0;
        this.periodicidad = 0;
        this.mes_inicio = 0;
    }
}
