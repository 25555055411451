export class Extintor {
    id: number;
    estudio_id: number;
    ubicacion_id: number;
    estudio: any;
    ubicacion: any;
    no_serie: string;
    etiqueta: string;
    capacidad: number;
    tipo_id: number;
    tipo: any;
    agente_id: number;
    agente: any;
    ultimo_servicio: string;
    fecha_fabricacion: string;
    ph: string;
    ph2: string;
    marca: string;
    status: number;
    observaciones: string;

    constructor() {
        this.estudio = {};
        this.ubicacion = {};
        this.tipo = {};
        this.agente = {};
    }
}
