import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';


@Injectable({ providedIn: 'root' })
export class GiroService {
    constructor(private http: HttpClient) { }

    getAll() {
        return this.http.get<any>(`${environment.apiUrl}giros`);
    }

    add(data) {

        const postData = {
            nombre: data.nombre.value,
            descripcion: data.descripcion.value,
        };

        return this.http.post<any>(`${environment.apiUrl}giros`, postData);
    }

    update(data, id) {

        const postData = {
            nombre: data.nombre.value,
            descripcion: data.descripcion.value,
        };

        return this.http.put<any>(`${environment.apiUrl}giros/` + id, postData);
    }

    getGiro(giro_id) {
        return this.http.get<any>(`${environment.apiUrl}giros/` + giro_id);
    }

    delete(giro_id) {
        return this.http.delete<any>(`${environment.apiUrl}giros/` + giro_id);
    }
}
