import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private headers = new HttpHeaders();
    constructor(private http: HttpClient) { }

    login(username: string, password: string) {

        const postData = {
                grant_type: 'password',
                client_id: environment.client_id,
                client_secret: environment.client_secret,
                username: username,
                password: password,
                scope: '*',
        };

        this.headers.append('Content-Type', 'application/json');
        this.headers.append('Accept', 'application/json');

        return this.http.post<any>(`${environment.apiUrl}oauth/token`, postData);
    }

    me() {
        this.headers.append('Content-Type', 'application/json');
        this.headers.append('Accept', 'application/json');

        return this.http.get<any>(`${environment.apiUrl}usuarios/me`);
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('access_token');
        localStorage.removeItem('logged_user');
    }
}
