import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './_guards';

import { FullComponent } from './layouts/full/full.component';
import {BlankComponent} from './layouts/blank/blank.component';

export const Approutes: Routes = [
  {
    path: '',
    component: FullComponent,
    children: [
      { path: '', redirectTo: '/starter', pathMatch: 'full' },
      {
        path: 'starter',
        loadChildren: './starter/starter.module#StarterModule',
        canActivate: [AuthGuard]
      },
        {
            path: 'clientes',
            loadChildren: './clientes/clientes.module#ClientesModule',
            canActivate: [AuthGuard]
        },
        {
            path: 'ordenes',
            loadChildren: './ordenes/ordenes.module#OrdenesModule',
            canActivate: [AuthGuard]
        },
        {
            path: 'estudios',
            loadChildren: './estudios/estudios.module#EstudiosModule',
            canActivate: [AuthGuard]
        },
        {
          path: 'reportes',
          loadChildren: './reportes/reportes.module#ReportesModule',
          canActivate: [AuthGuard]
        },
        {
          path: 'taller',
          loadChildren: './taller/taller.module#TallerModule',
          canActivate: [AuthGuard]
        },
        {
            path: 'usuarios_moviles',
            loadChildren: './usuarios_moviles/usuarios_moviles.module#UsuariosmovilesModule',
            canActivate: [AuthGuard]
        },
        {
          path: 'usuarios_recargadores',
          loadChildren: './usuarios_recargadores/usuarios_recargadores.module#UsuariosrecargadoresModule',
          canActivate: [AuthGuard]
        },
        {
            path: 'usuarios_administradores',
            loadChildren: './usuarios_administradores/usuarios_administradores.module#UsuariosadministradoresModule',
            canActivate: [AuthGuard]
        },
        {
            path: 'configuracion',
            loadChildren: './configuracion/configuracion.module#ConfiguracionModule',
            canActivate: [AuthGuard]
        },
      {
        path: 'component',
        loadChildren: './component/component.module#ComponentsModule'
      },
      {
        path: 'perfil',
        loadChildren: './perfil/perfil.module#PerfilModule'
      },
    ]
  },
    {
        path: '',
        component: BlankComponent,
        children: [
            {
                path: 'login',
                loadChildren: './login/login.module#LoginModule'
            },
        ]
    },
  {
    path: '**',
    redirectTo: '/starter'
  }
];
