import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';



@Injectable({ providedIn: 'root' })
export class GastoService {
    constructor(private http: HttpClient) { }

    add(data) {
        const postData = {
            concepto: data.concepto.value,
            cantidad: data.cantidad.value,
            proyecto_id: data.orden_id.value,
        };

        return this.http.post<any>(`${environment.apiUrl}gastos`, postData);
    }

    delete(gasto_id) {
        return this.http.delete<any>(`${environment.apiUrl}gastos/` + gasto_id);
    }
}
