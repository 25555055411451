export class Paso {
    id: number;
    fecha_estimado: string;
    fecha_terminado: string;
    descripcion: string;
    comentarios: string;
    status: number;
    orden: number;
    mostrar_cliente: number;
    proyecto_id: number;
    proyecto: object;
    bloque_id: number;
    bloque: object;

    constructor() {
        this.bloque = {};
        this.proyecto = {};
        this.fecha_estimado = '';
        this.fecha_terminado = '';

    }
}

