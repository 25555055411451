export class Status {
  id: number;
  evidencias: any;
  comentarios: any;
  problemas: any;

  constructor() {
    this.evidencias = [{}];
    this.comentarios = [{}];
    this.problemas = [{}];
  }
}
