import {Extintor} from './extintor';
import {Estudio} from './estudio';


export class Ubicacion {
    id: number;
    estudio_id: number;
    estudio: Estudio;
    numero: number;
    letra; string;
    etiqueta: string;
    descripcion: string;
    extintor: Extintor;

    constructor() {
        this.estudio =  new Estudio();
        this.extintor =  new Extintor();
        this.id = null;
        this.estudio_id = null;
        this.numero = null;
        this.letra = null;
        this.etiqueta = null;
        this.descripcion = null;
    }
}
