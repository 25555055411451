import { RouteInfo } from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [
  {
    path: '',
    title: 'Menú Principal',
    icon: 'mdi mdi-dots-horizontal',
    class: 'nav-small-cap',
    extralink: true,
    submenu: []
  },
  {
    path: '/starter',
    title: 'Dashboard',
    icon: 'fa fa-chart-line',
    class: '',
    extralink: false,
    submenu: []
  },
    {
        path: '/clientes',
        title: 'Clientes',
        icon: 'fa fa-users',
        class: '',
        extralink: false,
        submenu: []
    },
    {
      path: '/ordenes',
      title: 'Órdenes de Servicio',
      icon: 'fa fa-file',
      class: '',
      extralink: false,
      submenu: []
    },
    {
      path: '/taller',
      title: 'Taller',
      icon: 'fa fa-wrench',
      class: '',
      extralink: false,
      submenu: []
    },
    {
        path: '/reportes',
        title: 'Reportes',
        icon: 'fa fa-file',
        class: '',
        extralink: false,
        submenu: []
    },
    {
        path: '',
        title: 'Configuración',
        icon: 'mdi mdi-dots-horizontal',
        class: 'nav-small-cap',
        extralink: true,
        submenu: []
    },
    {
        path: '/usuarios_moviles',
        title: 'Usuarios móviles',
        icon: 'fa fa-users',
        class: '',
        extralink: false,
        submenu: []
    },
    {
      path: '/usuarios_recargadores',
      title: 'Usuarios recargadores',
      icon: 'fa fa-users',
      class: '',
      extralink: false,
      submenu: []
    },
    {
        path: '/usuarios_administradores',
        title: 'Administradores',
        icon: 'fa fa-users',
        class: '',
        extralink: false,
        submenu: []
    },
    {
        path: '/configuracion',
        title: 'Configuración',
        icon: 'fa fa-cog',
        class: 'has-arrow',
        extralink: false,
        submenu: [
            {
                path: '/configuracion/giros',
                title: 'Giros',
                icon: '',
                class: '',
                extralink: false,
                submenu: []
            },
            {
                path: '/configuracion/paises',
                title: 'Paises',
                icon: '',
                class: '',
                extralink: false,
                submenu: []
            },
            {
                path: '/configuracion/estados',
                title: 'Estados',
                icon: '',
                class: '',
                extralink: false,
                submenu: []
            },
            {
                path: '/configuracion/agentes',
                title: 'Agentes',
                icon: '',
                class: '',
                extralink: false,
                submenu: []
            },
            {
                path: '/configuracion/equipotipos',
                title: 'Tipos de extintores',
                icon: '',
                class: '',
                extralink: false,
                submenu: []
            },{
            path: '/configuracion/materiales',
            title: 'Materiales',
            icon: '',
            class: '',
            extralink: false,
            submenu: []
          },
        ]
    },
];

export const ROUTES_CLIENTE: RouteInfo[] = [
  {
    path: '',
    title: 'Menú Principal',
    icon: 'mdi mdi-dots-horizontal',
    class: 'nav-small-cap',
    extralink: true,
    submenu: []
  },
  {
    path: '/cliente_dashboard',
    title: 'Dashboard',
    icon: 'fa fa-chart-line',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/reportes',
    title: 'Reportes',
    icon: 'fa fa-file',
    class: '',
    extralink: false,
    submenu: []
  }
];
