import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';



@Injectable({ providedIn: 'root' })
export class OrdenService {
    constructor(private http: HttpClient) { }

    getAsignar() {
        return this.http.get<any>(`${environment.apiUrl}ordenes?status=0,1`);
    }

  getTrabajando() {
    return this.http.get<any>(`${environment.apiUrl}ordenes?status=2`);
  }

    getHistorial() {
        return this.http.get<any>(`${environment.apiUrl}ordenes?status=3`);
    }

    getTipos() {
        return this.http.get<any>(`${environment.apiUrl}proyectotipos`);
    }

    getOrden(orden_id) {
        return this.http.get<any>(`${environment.apiUrl}proyectos/` + orden_id);
    }

    getBloques() {
        return this.http.get<any>(`${environment.apiUrl}bloques`);
    }

    getPasos(orden_id) {
        return this.http.get<any>(`${environment.apiUrl}proyectos/` + orden_id + '/pasos');
    }

    add(data) {

        const postData = {
            numero_orden: data.numero_orden,
            cliente_id: data.cliente_id,
            estudio_id: data.estudio_id,
        };

        return this.http.post<any>(`${environment.apiUrl}ordenes`, postData);
    }

    addPaso(data) {
        const postData = {
            proyecto_id: data.proyecto_id.value,
            bloque_id: data.bloque_id.value,
            fecha_estimado: data.fecha_estimada.value + ' ' + data.hora_estimada.value,
            comentarios: data.comentarios.value,
            mostrar_cliente: data.mostrar_cliente.value,
        };

        return this.http.post<any>(`${environment.apiUrl}pasos`, postData);
    }

    editPaso(data,) {
        const postData = {
            id: data.id.value,
            proyecto_id: data.proyecto_id.value,
            bloque_id: data.bloque_id.value,
            fecha_estimado: data.fecha_estimada.value + ' ' + data.hora_estimada.value,
            comentarios: data.comentarios.value,
            mostrar_cliente: data.mostrar_cliente.value,
        };

        return this.http.put<any>(`${environment.apiUrl}pasos/` + data.id.value, postData);
    }

    delete(orden_id) {
        return this.http.delete<any>(`${environment.apiUrl}proyectos/` + orden_id);
    }

    deletePaso(paso_id) {
        return this.http.delete<any>(`${environment.apiUrl}pasos/` + paso_id);
    }

    getPaso(paso_id) {
        return this.http.get<any>(`${environment.apiUrl}pasos/` + paso_id);
    }

    asignarToda(order_id, user_id, numero = 0) {
      var postData = {}
      if(numero <= 0) {
        postData = {
          usuario_id: user_id
        };
      } else {
        postData = {
          usuario_id: user_id,
          numero: numero
        };
      }
      return this.http.put<any>(`${environment.apiUrl}ordenes/` + order_id + '/asignar', postData);
    }

  asignarExtintor(extintor_id, orden_id) {
    var postData = {}
    postData = {
      extintor_id: extintor_id,
    };
    return this.http.post<any>(`${environment.apiUrl}ordenes/` + orden_id + '/asignar', postData);
  }

}
