import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';


@Injectable({ providedIn: 'root' })
export class MaterialService {
    constructor(private http: HttpClient) { }

    getAll() {
        return this.http.get<any>(`${environment.apiUrl}materiales`);
    }

    add(data) {

        const postData = {
            nombre: data.nombre.value,
            descripcion: data.descripcion.value,
        };

        return this.http.post<any>(`${environment.apiUrl}materiales`, postData);
    }

    update(data, id) {

        const postData = {
            nombre: data.nombre.value,
            descripcion: data.descripcion.value,
        };

        return this.http.put<any>(`${environment.apiUrl}materiales/` + id, postData);
    }

    getMaterial(material_id) {
        return this.http.get<any>(`${environment.apiUrl}materiales/` + material_id);
    }

    delete(material_id) {
        return this.http.delete<any>(`${environment.apiUrl}materiales/` + material_id);
    }

   registrarMaterialOrden(material_id, orden_id, cantidad) {

      const postData = {
        material_id: material_id,
        cantidad: cantidad,
      };

      return this.http.post<any>(`${environment.apiUrl}ordenes/` + orden_id + '/registrarMaterial', postData);
    }
}
