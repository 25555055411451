import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { User } from '../_models';
import { environment } from '../../environments/environment';


@Injectable({ providedIn: 'root' })
export class UserService {
    constructor(private http: HttpClient) { }

    getAll(role_id = '') {
        return this.http.get<any>(`${environment.apiUrl}users?role_id=` + role_id);
    }

    add(data, role_id = '') {

        const postData = {
            name: data.name.value,
            email: data.email.value,
        };

        return this.http.post<any>(`${environment.apiUrl}users?role_id=` + role_id, postData);
    }

    update(data, id) {

        var postData = {
            name: data.name.value,
            email: data.email.value,
            password : null,
            password_confirmation : null
        };

        if (data.password) {
          postData.password = data.password.value
        }

        if (data.password_confirmation) {
          postData.password_confirmation = data.password_confirmation.value
        }

        return this.http.put<any>(`${environment.apiUrl}users/` + id, postData);
    }

    getUsuario(user_id) {
        return this.http.get<any>(`${environment.apiUrl}users/` + user_id);
    }

    delete(user_id) {
        return this.http.delete<any>(`${environment.apiUrl}users/` + user_id);
    }
}
