import { Component, AfterViewInit, OnInit } from '@angular/core';
import { ROUTES, ROUTES_CLIENTE } from './menu-items';
import { RouteInfo } from './sidebar.metadata';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
declare var $: any;
import {AuthenticationService} from '../../_services';
import {User} from '../../_models';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html'
})
export class SidebarComponent implements OnInit {
  user = User;
  showMenu = '';
  showSubMenu = '';
  public sidebarnavItems: any[];
  // this is for the open close
  addExpandClass(element: any) {
    if (element === this.showMenu) {
      this.showMenu = '0';
    } else {
      this.showMenu = element;
    }
  }
  addActiveClass(element: any) {
    if (element === this.showSubMenu) {
      this.showSubMenu = '0';
    } else {
      this.showSubMenu = element;
    }
  }

  constructor(
    private modalService: NgbModal,
    private router: Router,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService
  ) {}

  // End open close
  ngOnInit() {
    if ( localStorage.getItem('logged_user') ) {
      this.user = JSON.parse(localStorage.getItem('logged_user'));
      this.getMenu(this.user);
      console.log( this.user);
    } else {
      this.authenticationService.me()
          .subscribe(
              data => {
                localStorage.setItem('logged_user', JSON.stringify(data.data));
                this.user = data.data;
                this.getMenu(this.user);
              },
              error => {
                alert('Error al obtener información del usuario');
              });
    }
  }

  getMenu(user) {
    if ( user.roles[0].name === 'cliente') {
      this.sidebarnavItems = ROUTES_CLIENTE .filter(sidebarnavItem => sidebarnavItem);
    } else {
      this.sidebarnavItems = ROUTES.filter(sidebarnavItem => sidebarnavItem);
    }

    /*
    if ( user.roles[0].name === 'superadmin') {

    }
    */
  }

}
