import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';



@Injectable({ providedIn: 'root' })
export class ReporteService {
  constructor(private http: HttpClient) { }

  getAll() {
    return this.http.get<any>(`${environment.apiUrl}reportes`);
  }

  get(id) {
    return this.http.get<any>(`${environment.apiUrl}reportes/` + id);
  }

  delete(id) {
    return this.http.delete<any>(`${environment.apiUrl}reportes/` + id);
  }

  add(data, estudio_id) {
    const postData = {
      estudio_id: estudio_id,
      usuario_id: data.usuario_id.value,
      nombre: data.year.value + ' ' + data.mes.value,
      fecha: data.fecha.value,
    };
    return this.http.post<any>(`${environment.apiUrl}reportes`, postData);
  }

  edit(data, reporte_id) {

    const putData = {
      usuario_id: data.usuario_id.value,
      nombre: data.year.value + ' ' + data.mes.value,
      fecha: data.fecha.value,
    };

    return this.http.put<any>(`${environment.apiUrl}reportes/` + reporte_id, putData);
  }
}
