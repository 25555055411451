// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: 'https://api.omega.m22.fun/',
  client_id: 3,
  client_secret: 'sjoK7KXgce3lBY7SnDZJFMG9bOVcB6g61MqbaU20',
};
