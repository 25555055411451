import {User} from './user';
import {Estudio} from './estudio';

export class Reporte {
  id: number;
  estudio_id: number;
  estudio: Estudio;
  usuario_id: number;
  usuario: User;
  nombre: string;
  fecha: string;
  status: number;
  year: string;
  mes: string;


  constructor() {
    this.id = null;
    this.estudio =  new Estudio();
    this.estudio_id = null;
    this.usuario =  new User();
    this.usuario_id = null;
    this.nombre = null;
    this.fecha = null;
    this.status = null;
    this.year = null;
    this.mes = null;
  }
}
