import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';



@Injectable({ providedIn: 'root' })
export class ExtintorService {
    constructor(private http: HttpClient) { }

    getTipos() {
        return this.http.get<any>(`${environment.apiUrl}equipotipos`);
    }

    getAgentes() {
        return this.http.get<any>(`${environment.apiUrl}agentes`);
    }

    addComplete(data, estudio_id) {

        const postData = {
            no_serie: data.no_serie.value,
            numero: data.numero.value,
            letra: data.letra.value,
            tipo_id: data.tipo_id.value,
            capacidad: data.capacidad.value,
            agente_id: data.agente_id.value,
            ultimo_servicio: data.ultimo_servicio.value,
            localizacion: data.localizacion.value,
            marca: data.marca.value,
            fecha_fabricacion: data.fecha_fabricacion.value,
            ph: data.ph.value,
            ph2: data.ph2.value,
            observaciones: data.observaciones.value,
            estudio_id: estudio_id,
        };

        return this.http.post<any>(`${environment.apiUrl}ubicaciones/completo`, postData);
    }

    editComplete(data, estudio_id, ubicacion_id) {
      const postData = {
        no_serie: data.no_serie.value,
        numero: data.numero.value,
        letra: data.letra.value,
        tipo_id: data.tipo_id.value,
        capacidad: data.capacidad.value,
        agente_id: data.agente_id.value,
        ultimo_servicio: data.ultimo_servicio.value,
        localizacion: data.localizacion.value,
        marca: data.marca.value,
        fecha_fabricacion: data.fecha_fabricacion.value,
        ph: data.ph.value,
        ph2: data.ph2.value,
        observaciones: data.observaciones.value,
        estudio_id: estudio_id,
      };
      return this.http.put<any>(`${environment.apiUrl}ubicaciones/` + ubicacion_id + `/completo`, postData);
    }

    edit(data, extintor_id) {
      return this.http.put<any>(`${environment.apiUrl}extintores/` + extintor_id, data);
    }

    deleteComplete(ubicacion_id) {
        return this.http.delete<any>(`${environment.apiUrl}ubicaciones/` + ubicacion_id);
    }

    delete(extintor_id) {
      return this.http.delete<any>(`${environment.apiUrl}extintores/` + extintor_id);
    }

    get(id) {
      return this.http.get<any>(`${environment.apiUrl}extintores/` + id);
    }

    escanearExtintor(etiqueta) {
      return this.http.get<any>(`${environment.apiUrl}extintores/escanear/` + etiqueta );
    }

    iniciarTaller(extintor_id, usaurio_id){
      const postData = {
        extintor_id: extintor_id,
        usuario_id: usaurio_id,
      };
      return this.http.put<any>(`${environment.apiUrl}taller/iniciar`, postData);
    }

    pinturaTaller(extintor_id){
      const postData = {
        extintor_id: extintor_id,
      };
      return this.http.put<any>(`${environment.apiUrl}taller/pintura`, postData);
    }

  terminarTaller(usuario_id){
      const postData = {
        usuario_id: usuario_id,
      };
      return this.http.put<any>(`${environment.apiUrl}taller/terminar`, postData);
    }
}
